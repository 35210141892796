/**
 * Content oldal
 */
#site-content {
	margin: 0 auto;

	.content & {
		max-width: 1280px;
		padding: 0 16px 1px 16px;
		background-color: white;
	}

	.content.view-wide-content & {
		max-width: none;

		.site-content-panel {
//			max-width: 1228px;
//			margin: 0 auto;
		}
	}

	@include media-breakpoint-down(lg) {
		.sidebar {
			margin-top: 10px;
		}
	}

	@include media-breakpoint-up(lg) {
		&.panel-left,
		&.panel-right {
			display: flex;
		}

		.sidebar {
			width: 25%;

			&.boxpanel-left {
				order: -1;
			}
		}

		&.panel-left,
		&.panel-right {
			& > .site-content-panel {
				flex: 1;
			}
		}

		&.panel-left {
			padding-left: 0;
		}

		&.panel-right {
			padding-right: 0;
		}
	}
}

/**
 * Méretezhető vendébox tartó.
 */
.sized-box {
	position: relative;
	height: var(--panel-height);

	&.noheight {
		height: auto;
	}

	&.h-percent {
		height: 0;
		padding-bottom: var(--panel-height);
	}

	& > .sized-panel {
		display: block;
	}

	&:not(.noheight) > .sized-panel {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.sized-iframe {
		display: block;
		width: 100%;
		height: 100%;
		border: none;
	}
}

