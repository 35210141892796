/**
 * Boxok
 */
.box {

	& > h1 {
		width: 90%;
		text-align: center;
		margin: 0 auto 10px auto;
		color: $site-color;
		font-family: $site-font-family;
		font-size: 38px;
		font-weight: normal;
	}

	& > h1 + hr,
	& > h2 + hr {
		border-top: 1px solid #7C7C7C;
		margin: 0;
	}

	&.top-margin {
		margin-top: 15px;
	}

	&.bottom-margin {
		margin-bottom: 15px;
	}

	&.white-bcg {
		background-color: white;
	}

	&.red-bcg {
		background-color: $site-secondary-color;
	}

	&.red-bcg,
	&.white-txt {
		& > h1,
		& > h2 {
			color: white;
		}

		& > h1 + hr,
		& > h2 + hr {
			border-top-color: white;
		}
	}
}

@import 'box_title';
@import 'box_content';
@import 'box_list';
@import 'box_menulist';
@import 'box_imagelist';
@import 'box_contact';

/* guest box */
.box.box-guest {
	text-align: center;

	&.hiddenboxtitle > h1 {
		display: none;
	}
}
