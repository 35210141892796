@import '../../vendor/twbs/bootstrap/scss/_functions';
@import '../../vendor/twbs/bootstrap/scss/_variables';
@import '../../vendor/twbs/bootstrap/scss/_mixins';

@import 'colors';
@import 'fonts';

:root {
	font-size: $site-font-size;
}

body.bic {
	background: $site-background;
	background-attachment: fixed;
	font-family: $site-font-family;
	font-weight: normal;
}

body.bic.fixed-header {
	margin-top: 116px;
}

.block {
	max-width: $site-width;
	margin: 0 auto;
}

a {
	transition: color 0.2s, background-color 0.2s;
}

.gothic-debug {
	background-color: #dddddd;
	color: black;
	border: 1px solid black;
	padding: 5px;
	white-space: pre-line;
}

.clearfix:after {
	content: "";
	display: table;
	clear: both;
	visibility: hidden;
	border-collapse: collapse;
}

@import 'boxpanel';
@import 'form';
@import 'freetext';
@import 'frame';
@import 'boxes';
@import 'icons';
@import 'content';
@import 'article';
@import 'map';
@import 'upload';
@import 'autocomplete';
@import 'slider';
