.content.upload {
	.gmap {
		min-height: 400px;
	}

	#image {
		margin-top: 1em;
		margin-bottom: 1em;

		.boximg-preview {
			display: block;
			margin: 0 auto;
		}
	}
}