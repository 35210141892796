@import 'freetext-resources';

/**
 * Freetext styles
 */
.txt {
	color: inherit;
	font-size: 100%;

	a {
		color: $freetext-link-color;
		text-decoration: underline;
	}
	strong,
	b {
		font-weight: bold;
	}
	i,
	em {
		font-style: italic;
	}
	sup {
		font-size: smaller;
		line-height: normal;
		vertical-align: super;
	}
	sub {
		font-size: smaller;
		line-height: normal;
		vertical-align: sub;
	}
	ul {
		list-style-type: disc;
		list-style-position: inside;
	}
	ol {
		list-style-type: decimal;
		list-style-position: inside;
	}
	& > ul,
	& > ol {
		margin: 15px 0;
		margin-left: 1.75em;
	}
	p {
		margin: 15px 0;
	}
	& > *:first-child {
		margin-top: 0;
	}
	& > *:last-child {
		margin-bottom: 0;
	}
	li > p {
		display: inline;
		margin: 0;
	}
	.left {
		text-align: left;
	}
	.right {
		text-align: right;
	}
	.center {
		text-align: center;
	}
	.justify {
		text-align: justify;
	}
	.clear {
		clear: both;
	}
	.full-width {
		margin-left: 0;
		margin-right: 0;
	}
	span.image {
		display: block;
		text-align: center;
		font-size: 15px;
		font-style: normal;
		font-variant: normal;
		font-weight: bold;
		line-height: 1.2;
		color: $site-secondary-color;

		img {
			display: block;
			position: relative;
			margin-bottom: 9px;
			border: 0;

			background-color: #c52659;
			padding-left: 10px;
			border: 1px solid #c52659;
			border-radius: 8px;

			&:before {
				display: block;
				position: absolute;
				content: "";
				width: 10px;
				top: 0;
				bottom: 0;
			}
		}

		&.baseline img {
			width: 100%;
		}

		&.none img {
			margin-bottom: 0;
		}
	}
	span.video_panel {
		display: block;
		&.baseline {
			text-align: center;

			& > .video {
				text-align: center;

				img, video {
					max-width: 100%;
				}
			}
		}

		& > .video {
			margin: 0 auto;
		}
	}
	span.image.left,
	span.video_panel.left {
		float: left;
		padding-right: 10px;
	}
	span.image.center img {
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
		height: auto;
	}
	span.image.right,
	span.video_panel.right {
		float: right;
		padding-left: 10px;
	}
	span.image.cright,
	span.video_panel.cright {
		clear: right;
	}
	span.embed {
		display: block;
		position: relative;
	}

	.h1 {
		font-size: 34px;
	}
	.h2 {
		font-size: 34px;
		color: #ff991b;
	}
	.h3 {
		font-size: 27px;
	}
	.s {
		font-size: 83.3333%;
	}
	.l {
		font-size: 116.6666%;
	}
	.xl {
		font-size: 133.3333%;
	}
	.red {
		color: $site-secondary-color;
	}

	.ctable {
		overflow: auto;
		max-width: 100%;
		font-family: $control-font-family;
		color: white;
		font-size: 17.333px;

		& > table {
			width: 100%;
		}

		a {
			color: white;
		}

		td {
			border-right: 1px solid white;
			border-bottom: 1px solid white;
			padding: 7px 9px;
			background-color: #ea7d95;

			&:last-child {
				border-right: none;
			}

			&.th1 {
				background-color: transparent;
				color: $site-secondary-color;
				font-size: 33.33px;
				font-weight: bold;
			}
			&.crow-simple {
				background-color: transparent;
				color: #737373;

				a {
					color: #737373;
				}
			}

			&.chead {
				font-size: 21.333px;
				background-color: $site-secondary-color;
			}
			&.crow2 {
				background-color: #e15973;
			}
			&.crow-gray1 {
				background-color: #9d9d9d;
			}
			&.crow-gray2 {
				background-color: #b2b2b2;
			}
			& > p:first-child {
				margin-top: 0;
			}
			& > p:last-child {
				margin-bottom: 0;
			}
			&.cleft {
				text-align: left;
			}
			&.ccenter {
				text-align: center;
			}
			&.cright {
				text-align: right;
			}
			&.cjustify {
				text-align: justify;
			}
			&.ctop {
				vertical-align: top;
			}
			&.cmiddle {
				vertical-align: middle;
			}
			&.cbottom {
				vertical-align: bottom;
			}
		}
	}

	@media (max-width: 767px) {
		span.image {
			img {
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
			}

			&.left,
			&.right {
				width: 100%;
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}
