/**
 * Contact box
 */
.box.box-contact {
	font-family: $content-font-family;

	h1 {
		font-family: inherit;
		font-size: 24px;
		font-style: normal;
		font-variant: normal;
		font-weight: bold;
		line-height: 1.2;
		text-align: left;
		margin: 0 0 1rem 0;
	}

	h2 {
		font-family: inherit;
		font-size: 16px;
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		line-height: 1.2;
		text-align: left;
		text-indent: 0;
		text-transform: none;
		margin: 0 0 2rem 0;
	}
}