/**
 * Article/Product/Event oldal
 */
.content {
	font-family: $content-font-family;

	& > h1 {
		padding: 0;
		margin: 1.3rem 0 2rem 0;
		color: $site-secondary-color;
		font-size: 24px;
		line-height: 29px;
		font-style: normal;
		font-variant: normal;
		font-weight: bold;
	}

	& > h2 {
		padding: 0 0 0 0;
		margin: 0 0 4px 2px;
		color: $site-secondary-color;
		font-size: 24px;
		font-weight: normal;
	}

	& > h3 {
		padding: 0;
		margin: -1.5rem 0 2rem 0;
		color: $site-secondary-color;
		font-size: 20px;
		font-weight: normal;
	}

	& > h4 {
		margin: 1.3rem 0 -1rem 0;
		color: #808080;
		font-size: 11px;
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		line-height: 1.2;
	}

	article.txt {
		font-family: $content-font-family;
		font-size: 18px;
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		line-height: 1.4;
		text-indent: 0;
		color: #000000;
	}

	ul.join-url {
		display: block;
		padding: 0;
	}

	section.cpart {
		margin-top: 1.3rem;
		margin-bottom: 1.3rem;

		& > .section-tite {
			border-bottom: 4px solid #353637;
			margin-bottom: 0;

			& > .title {
				display: inline-block;
				background-color: #353637;
				padding: 2px 15px 0 15px;
				margin-right: 10px;
				font-family: $site-font-family;
				font-size: 18px;
				font-style: normal;
				font-variant: normal;
				font-weight: normal;
				line-height: 40px;
				text-transform: uppercase;
				color: white;
			}

			& > .subtitle {
				color: $site-secondary-color;
				font-family: $content-font-family;
				font-size: 24px;
				font-style: normal;
				font-variant: normal;
				font-weight: bold;
			}
		}

		&.gallery-part {
			margin: 1.8rem 0;

			& > .images {
				padding: 20px 15px;
				background-color: #e5e5e5;
			}
		}

		&.iframe-part {
			.iframe-holder {
				width: 100%;
			}
		}
	}

	.product-gallery {
		.image-thread-panel {
			width: 100%;
			overflow: hidden;
			position: relative;
		}

		.image-thread {
			position: relative;
			display: flex;
			margin: 4px -2px;

			.image {
				position: relative;
				margin: 0 2px;
			}

			a {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}
		}

		.big-image {
			padding-bottom: 75%;
			position: relative;
			overflow: hidden;

			.big-image-body {
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background-image: var(--showcase-image);
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;

				&.enter,
				&.leave {
					animation-duration: .5s;
				}

				&.enter {
					&.move-left {
						animation-name: product-gallery-enter-left;
					}

					&.move-right {
						animation-name: product-gallery-enter-right;
					}
				}

				&.leave {
					&.move-left {
						animation-name: product-gallery-leave-left;
					}

					&.move-right {
						animation-name: product-gallery-leave-right;
					}
				}
			}
		}

		.step-button {
			display: flex;
			position: absolute;
			top: 0;
			bottom: 0;
			width: 60px;
			align-items: center;
			align-content: center;
			background-color: transparent;
			transition: background-color 0.3s, color 0.4s;
			color: rgba(0, 0, 0, 0.5);

			&:hover {
				background-color: rgba(255, 255, 255, 0.5);
				color: black;
				text-decoration: none;
			}

			&.left {
				left: 0;
			}

			&.right {
				right: 0;
			}
		}
	}

	table.properties {
		width: 100%;
		margin-bottom: 1rem;

		td, th {
			width: 50%;
			height: 34px;
			font-weight: normal;
			padding: 0 5px;

			border-bottom: 1px solid #808080;
		}

		th {
			background-color: #E5E5E5;
		}

		tr:last-child {
			& > td, & > th {
				border-bottom: 0
			}
		}
	}

	.price {
		color: $site-secondary-color;
		font-size: 16px;
		font-style: normal;
		font-weight: bold;
		margin-top: 1rem;
	}
}

@keyframes product-gallery-enter-left {
	from {
		transform: translateX(-100%);
		opacity: 0.5;
	}

	to {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes product-gallery-enter-right {
	from {
		transform: translateX(100%);
		opacity: 0.5;
	}

	to {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes product-gallery-leave-left {
	from {
		transform: translateX();
		opacity: 1;
	}

	to {
		transform: translateX(100%);
		opacity: 0;
	}
}

@keyframes product-gallery-leave-right {
	from {
		transform: translateX(0);
		opacity: 1;
	}

	to {
		transform: translateX(-100%);
		opacity: 0;
	}
}

.content ul.join-url > li {
	display: block;
	color: #006ab2;
	font-weight: 500;
}

.content ul.join-url > li:before {
	display: inline-block;
	padding-right: 5px;
	content: "> ";
}

.content ul.join-url > li > a {
	color: inherit;
}

.content .controls {
	margin: 16px 0;
}

/* tags */
.tags-panel {
	display: block;
	margin: 0.5em 0;
}

.tags-panel > .tags {
	display: block;
	margin: 0;
	padding: 0;
}

.tags-panel > ul.tags > li {
	display: inline-block;
	padding: 4px 5px;
	margin: 0 3px 0 0;
	background-color: #f7941d;
	color: white;
	font-weight: 400;

	transition: background-color 0.3s;
}

.tags-panel > ul.tags > li:hover {
	background-color: #f26522;
}

/* product properties */
.content.product div.properties {
	margin: 22px 0 12px 0;
}

.content.product div.properties > h3 {
	font-size: 22px;
	color: #006ab2;
	font-weight: normal;
}

/*
table.properties {
	width: 100%;
	font-size: 16px;
	border: 1px solid #e1e1e1;
	color: rgb(107, 108, 108);
	font-size: 15.6px;
}

table.properties > thead {
	text-align: left;
	background-color: #f8f8f8;
}

table.properties tr {
	background-color: transparent;
	font-weight: normal;
}

table.properties tr.row2 {
	background-color: #f8f8f8;
	color: rgb(0, 110, 183);
}

table.properties tr.hilight {
	font-weight: bold;
}

table.properties th,
table.properties td {
	border: 1px solid #e1e1e1;
	padding: 9px 12px;
	font-weight: inherit;
}
*/

/* downloads */
.join-download {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 1rem 0 0 0;
	padding: 0;

	& > .download {
		display: block;
		background-color: $site-secondary-color;
		color: white;
		margin-bottom: 10px;

		width: 100%;

		@media (min-width: 767px) {
			width: calc(50% - 25px);
		}

		@media (min-width: 993px) {
			width: calc(33% - 25px);
		}

		& > .download-item {
			display: flex;
			align-items: center;

			& > .left {
				padding: 9px;
			}

			& > .right {

			}
		}
	}

	.icon {
		color: $site-secondary-color;
		font-size: 52px;
		border-radius: 10px;
		background-color: #deecf5;
	}

	.info {
		display: block;
		font-size: 16px;
		line-height: 1.2;

		&.title {
			font-size: 20px;
		}
	}

	a {
		color: inherit;
		text-decoration: inherit;
	}

}
