/* menulista box */
.box.simplelist {
	color: $site-color;
	font-family: $control-font-family;
	font-size: 14px;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1.2;

	&.hiddenboxtitle > h1,
	&.hiddenboxtitle > h1 + hr {
		display: none;
	}

	&.big {
		font-size: 21.333px;
		line-height: 1.563;
	}

	.box-body {
	}

	ul.menulist,
	ul.linklist {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		margin: 0;
	}

	li.item {
		display: block;
		padding: 0 10px;
		margin: 0;
		border-bottom: 1px solid #7c7c7c;
		width: 50%;

		&:last-child {
			border-bottom: none;
		}

		& > a {
			font-size: inherit;
			text-decoration: inherit;
			color: inherit;

			&:before {
				content: "> ";
			}

			&:hover {
				color: $site-secondary-color;
			}
		}

		.icon {
			color: $site-secondary-color;
		}
	}

	&.white-txt,
	&.red-bcg {
		color: white;

		li.item {
			border-bottom-color: white;
		}
	}

	&.small li.item {
		border-bottom: none;
	}
}

.box.fancylist {

	&.fancy {
		header {
//			max-width: 400px;

			& > h1 {
				font-size: 70px;
				line-height: 78px;
//				max-width: 400px;
				font-weight: 700;
				letter-spacing: .10125rem;

				@include media-breakpoint-down(lg) {
					font-size: 58px;
				}

				@include media-breakpoint-down(md) {
					font-size: 40px;
				}
			}
		}

		.item {
			position: relative;
			margin-left: 3rem;
		}

		h2 {
			line-height: 35px;
			font-size: 24px;
			font-weight: 700;
			margin-top: .75rem;
			margin-bottom: 0;
		}

		.minilead {
			line-height: 28px;
			font-size: 16px;
			margin-bottom: 1.5rem;
		}

		.icon {
			position: absolute;
			left: -3rem;
			top: 2px;
		}

		.controls {
			margin-top: 1.5rem;
		}
	}

	&.accordion {
		header {
//			max-width: 400px;

			& > h1 {
				line-height: 69px;
//				max-width: 400px;
				font-size: 54px;
				font-weight: 700;
				letter-spacing: .10125rem;
			}
		}

		h2 {
			cursor: pointer;
			font-size: 24px;
			font-weight: 700;
			letter-spacing: 0.32px;
			line-height: 36px
		}

		.item {
			position: relative;
			padding: 1rem 2rem 1rem 3rem;
			border-bottom: 1px solid $site-secondary-color;

			&:first-child {
				border-top: 1px solid $site-secondary-color;
			}
		}

		.minilead-container {
			overflow: hidden;
			height: 0;
			opacity: 0;
			transition: height .4s, opacity 1s;
		}

		.bullet {
			position: absolute;
			left: 4px;
			top: 19px;
			font-size: 26px;
			transition: transform 0.3s;

			&.bullet-open {
				transform: none;
			}

			&.bullet-close {
				transform: rotateX(0.25turn) rotateY(0.25turn);
			}
		}

		.item.open {
			.minilead-container {
				opacity: 1;
				transition: height .4s, opacity .3s;
			}

			.bullet {
				&.bullet-open {
					transform: rotateX(0.25turn) rotateY(0.25turn);
				}

				&.bullet-close {
					transform: none;
				}
			}
		}

		.minilead {
			font-size: 16px;
			font-weight: 400
		}

	}
}

.box.cards {
	header {
		& > hr {
			display: none;
		}

		& > h1 {
			font-size: 54px;
			font-weight: 700;
			letter-spacing: 1.62px;
			line-height: 69px;
			margin-bottom: 3rem;
			margin-top: 0px;
			padding-bottom: 0px;
			padding-left: 0px;
			padding-right: 0px;
			padding-top: 0px;
			text-align: center
		}
	}

	.cnt {
		display: block;
		margin-bottom: 1.5rem;

		& > i {
			display: block;
			color: white;
			text-align: center;
			width: 50px;
			height: 50px;
			background-color: $site-secondary-color;
			border-radius: 50%;
			font-size: 24px;
			font-weight: 700;
			line-height: 50px;
			font-style: normal;
			margin: 1.5rem auto;
		}

		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 5px;
			border-radius: 5px;
			background-color: $site-secondary-color;
		}
	}

	.linklist {
		padding-left: 0;
		padding-right: 0;

		.item {
			display: block;
		}
	}

	.box-body {
		display: block;
	}

	h2,
	.txt {
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.32px;
		line-height: 28px;
		margin: 0;
		padding: 0;
	}
}

