.autocomplete {
	display: block;
	position: relative;

	& > .dropdown {
		display: none;
		position: absolute;
		width: 100%;
		background-color: white;
		border: 1px solid #3c7314;

		&.show {
			display: block;
		}
	}
}