.box.box-imagelist {

	&.hiddenboxtitle > h1 {
		display: none;
	}

	&.single {
		&.single-head {
			margin-left: -15px;
			margin-right: -15px;
		}

		.image img {
			width: 100%;
		}
	}

	&.multi {
		.subbox {
			background-color: transparent;
			transition: background-color 0.3s;

			&:hover {
				background-color: #e5e5e5;

				.box-header {
					color: $site-secondary-color;
				}
			}
		}

		.subbox-item {
			position: relative;
		}

		&.talign-left .box-header {
			text-align: left;
		}

		&.talign-right .box-header {
			text-align: right;
		}

		&.talign-center .box-header {
			text-align: center;
		}

		.box-header {
			margin: 4px 0 4px 0;
			color: #303133;
			font-size: 29px;
			font-weight: normal;
			transition: color 0.3s;

			& > a.title {
				color: inherit;

				&:hover {
					color: $site-secondary-color;
					text-decoration: none;
				}
			}

			&.subtitle {
				margin-top: 1rem;
				margin-bottom: 5px;
				font-size: 20px;

				& + .box-content {
					margin-top: 0;
				}
			}
		}

		.image {
			width: 100%;
			position: relative;

			& > img {
				width: 100%;
				max-width: 100%;
			}

			.alert-title {
				display: block;
				position: absolute;
				right: 0px;
				top: 16px;
				font-size: 14px;
				background-color: $site-secondary-color;
				padding: 6px 12px 6px 16px;
				color: white;
			}
		}

		.box-content {
			margin-top: 1rem;
			font-family: $control-font-family;

			&.txt {
				font-size: 14px;
			}
		}

	}

	a.shader {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}

}

