@font-face {
	font-family: "mfn";
	src: url("mfn-icons.woff") format("woff");
}

@mixin def-icon($name, $char) {
	&.icon-#{$name}::after {
		content: $char;
	}
}

.icon {
	display: inline-block;
	font-family: mfn;
	font-style: normal;
	text-align: center;
	vertical-align: middle;
	width: 28px;
	height: 28px;
	line-height: 28px;
	font-size: 28px;
	color: inherit;

	&.icon-big {
		width: 36px;
		height: 36px;
		line-height: 36px;
		font-size: 36px;
	}

	@media (min-width: 768px) {
		&.icon-md-big {
			width: 36px;
			height: 36px;
			line-height: 36px;
			font-size: 36px;
		}
	}

	&.icon-tiny {
		width: 18px;
		height: 18px;
		line-height: 18px;
		font-size: 18px;
	}

	&.icon-small {
		width: 22px;
		height: 22px;
		line-height: 22px;
		font-size: 22px;
	}

	&.icon-large {
		width: 64px;
		height: 64px;
		line-height: 64px;
		font-size: 64px;
	}

	&.icon-huge {
		width: 80px;
		height: 80px;
		line-height: 80px;
		font-size: 80px;
	}

	@include def-icon("search", "\e91c");
	@include def-icon("basket", "\e814");
	@include def-icon("register", "\e94c");
	@include def-icon("favorite", "\e89a");
	@include def-icon("favorited", "\e899");
	@include def-icon("user", "\e94b");
	@include def-icon("login", "\e94b");
	@include def-icon("logout", "\e8cc");
	@include def-icon("pin", "\e8c6");
	@include def-icon("bell", "\e817");
	@include def-icon("home", "\e89d");
	@include def-icon("delete", "\e82a");
	@include def-icon("close", "\e82a");
	@include def-icon("photo", "\e829");
	@include def-icon("down", "\e867");
	@include def-icon("down-triangle", "\e866");
	@include def-icon("down-arrow", "\e868");
	@include def-icon("left", "\e8b7");
	@include def-icon("left-triangle", "\e8b6");
	@include def-icon("left-arrow", "\e8b8");
	@include def-icon("right", "\e915");
	@include def-icon("right-triangle", "\e914");
	@include def-icon("right-arrow", "\e916");
	@include def-icon("up", "\e945");
	@include def-icon("up-triangle", "\e944");
	@include def-icon("up-arrow", "\e946");
	@include def-icon("way-top", "\e941");
	@include def-icon("plus", "\e8fb");
	@include def-icon("minus", "\e8d8");
	@include def-icon("trashcan", "\e93c");
	@include def-icon("download", "\e863");
	@include def-icon("calendar", "\e826");
	@include def-icon("address", "\e85d");
	@include def-icon("preview", "\e8f5");
	@include def-icon("bill", "\e8dd");
	@include def-icon("facebook", "\f30e");
	@include def-icon("twitter", "\f309");
	@include def-icon("instagram", "\f319");
	@include def-icon("google", "\f059");
	@include def-icon("download", "\e86b");
	@include def-icon("hamburger", "\e8d6");
	@include def-icon("pin", "\e8c6");
	@include def-icon("phone", "\e827");
	@include def-icon("check", "\e841");
}


