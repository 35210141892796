/**
 * Breadcrumb
 */
div.breadcrumb {
	display: block;
	max-width: $site-width;
	color: $site-secondary-color;
	font-family: $content-font-family;
	font-size: 14px;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1.2;
	text-align: left;
	text-indent: 0;
	text-transform: none;
	background-color: white;
	margin: 0 auto;
	padding: 0;
	border-radius: 0;

	@media (max-width: 767.97px) {
	}

	body.view-breadcumbless & {
		display: none;
	}
	body.view-wide-breadcrumb & {
		max-width: none;

		ul.breadcrumb {
			max-width: $site-width;
			margin: 0 auto;
		}
	}

	.breadcrumb-bcg {
		background-color: #353637;
	}

	& > .breadcrumb-panel {
		width: 100%;
	}

	ul.breadcrumb {
		margin: 0;
		padding: 0 0 0 20px;
		display: block;
		height: 32px;
		line-height: 32px;
		background-color: transparent;

		& > li {
			display: inline-block;

			@media (max-width: 767.97px) {
				font-size: 12px;
			}

			&:after {
				content: " / ";
			}

			&:last-child {
				text-decoration: underline;
			}

			&:last-child:after {
				content: "";
				display: none;
			}
		}

		a {
			color: inherit;
		}
	}
}
