@font-face {
	font-family: "Anton";
	src: url("fonts/Anton-Regular.ttf");
}

@font-face {
	font-family: "Spartan";
	src: url("fonts/Spartan-VariableFont_wght.ttf");
}

@font-face {
	font-family: "Roboto Lt";
	src: url("fonts/Roboto-Light.ttf");
}

@font-face {
	font-family: "Roboto";
	src: url("fonts/Roboto-Regular.ttf");
}
