$form-border-color: white;
$form-bg-color: #FF991B;
$form-input-color: black;
$form-input-height: 46px;
$form-input-font-size: 14px;
$form-error-border-color: $form-border-color;
$form-error-bg-color: $form-border-color;
$form-error-input-color: black;

.form {
	background-color: #E1E1E2;
	padding: 25px 32px;
	color: black;

	label, legend {
		font-size: $form-input-font-size;
		font-family: $content-font-family;
		line-height: 1.704;
		margin-bottom: .25rem;

		&.form-check-label {
			font-size: 18px;
			width: 100%;
		}
	}

	.form-text {
		font-size: 14px;
		color: rgb(115, 115, 115);
	}

	.form-control,
	.custom-file-input,
	.custom-file {
		font-size: $form-input-font-size;
		font-family: $control-font-family;
		line-height: $form-input-height;
		height: $form-input-height;
		color: $form-input-color;
	}

	.custom-file-label {
		height: $form-input-height;
	}

	.custom-file-label::after {
		height: $form-input-height - 2px;
	}

	.form-control,
	.custom-file-input {
		border-color: $form-border-color;
		border-radius: 4px;
		background-color: $form-bg-color;

		&:focus {
			border-color: $form-border-color;
			box-shadow: 0 0 0 .2rem $form-border-color;
			background-color: #fff;
		}

		&::placeholder {
			color: black;
		}
	}

	textarea.form-control {
		height: auto;
	}

	.form-control.is-invalid,
	.is-invalid .form-control {
		color: $form-error-input-color;
		background-color: $form-error-bg-color;
		border-color: $form-error-border-color;
	}

	h3 {
		margin-top: 1.2rem;
		margin-bottom: 0.6rem;
	}

	& > h3:first-child {
		margin-top: 0;
	}

	.form-check {
		padding-left: 2.5rem;
	}

	.form-check-input {
		margin-top: 7px;
	}

	.form-check-label {
		padding-left: 6px;
	}

	.form-optional-area {
		display: block;
		overflow: hidden;
		transition: height 0.5s;
		height: 0px;
	}

	.invalid-feedback {
		font-size: 14px;
	}

	fieldset > .invalid-feedback {
		display: block;
	}

	.btn {
		border-radius: 4px;
		font-size: 14px;

		&.btn-primary {
			background-color: white;
			border: none;
			color: black;

			&:hover {
				background-color: #FF991B;
			}
		}
	}
}

/**
 * Gombok
 */
.button {
	display: inline-block;
	background-color: $form-border-color;
	border-radius: 5px;
	color: white;
	text-transform: uppercase;
	font-size: 22px;
	padding: 2px 10px;
	transition: background-color 0.2s;

	&:hover {
		color: white;
		background-color: #F26522;
		text-decoration: none;
	}

	&.green {
		background-color: #8dc73f;
	}
}

/**
 * Bootstrap inputok
 */
.btn {
	padding: .7rem 1.9rem;
	border-width: 2px;
	border-radius: 18px;
	font-size: 16px;
	font-family: $control-font-family;
	font-weight: 500;

	.white-txt & {
		color: white;
	}

	&.btn-primary {
		background-color: $site-secondary-color;
		border-color: $site-secondary-color;
		color: black;
		transition: background-color .3s, border-color .3s, color .15s;

		&:hover {
			color: white;
			background-color: transparent;
			border-color: $site-secondary-color;
		}
	}

	&.btn-secondary {
		color: #353637;
		background-color: transparent;

		.white-txt & {
			color: white;
			background-color: transparent;
			border-color: white;
		}
	}
}

.btn ~ .btn {
	margin-left: 1rem;
}

.white-txt {
	.form-control {
		border-color: white;
		background-color: $site-secondary-color;
		color: black;

		&::placeholder {
			color: black;
		}

		&:focus {
			border-color: white;
			box-shadow: 0 0 0 .2rem white;
			background-color: lighten($site-secondary-color, 5%);
		}
	}

	label {
		color: white;
	}

	.form-text {
		color: white;
	}
}
