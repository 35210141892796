/* menulista box, szimpla contentlist box */
.box.box-menulist {
	font-size: 14px;
	line-height: 20px;
	color: $site-color;
	font-family: $control-font-family;

	&.hiddenboxtitle > h1,
	&.hiddenboxtitle > h1 + hr {
		display: none;
	}

	&.white-txt {
		color: white;

		& ul.menulist > li.item > a {
			border-bottom-color: white;
		}
	}

	a {
		color: inherit;

		&:hover {
			text-decoration: inherit;
		}
	}

	ul.menulist {
		display: block;
		padding: 0;
		margin: 0;

		& > li.item {
			display: block;
			position: relative;
			padding: 0 0 0 16px;
			margin: 0;

			& > a {
				display: block;
				border-bottom: 1px solid $site-color;

				&:hover {
					color: $site-secondary-color;
					border-color: $site-secondary-color;
				}
			}

			&.lvl2 > a {
				padding-left: 20px;
			}

			&:before {
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 16px;
				height: 16px;
				color: $site-secondary-color;
				font-family: mfn;
				font-size: 20px;
				height: 20px;
				line-height: 20px;
				text-align: center;
				content: "\e915";
			}
		}
	}
}

/**
 * Extended menülista box.
 */
.box.box-menulist.extended {
	text-align: center;

	& > .box-body {
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		margin: 1rem -10px;
	}

	.menu-item {
		padding: 10px;
	}

	&.single .menu-item { width: 100%; }
	&.double .menu-item { width: 50%; }
	&.triple .menu-item { width: 33.3333%; }
	&.quadruple .menu-item { width: 25%; }
	&.items-6 .menu-item { width: 16.6667%; }
	&.items-8 .menu-item { width: 12.5%; }

	@media (max-width: 993px) {
		&.double .menu-item { width: 100%; }
		&.triple .menu-item { width: 50%; }
		&.quadruple .menu-item { width: 33.3333%; }
		&.items-6 .menu-item { width: 33.3333%; }
		&.items-8 .menu-item { width: 25%; }
	}

	@media (max-width: 767px) {
		&.double .menu-item { width: 100%; }
		&.triple .menu-item { width: 100%; }
		&.quadruple .menu-item { width: 50%; }
	}

	.manu-item-box {
		display: block;
		position: relative;
	}

	.image {
		display: block;
		width: 100%;

		& > img {
			width: 100%;
		}
	}

	.title {
		display: block;
		position: absolute;
		z-index: 1;
		text-align: left;
		font-size: 24px;
		font-family: $site-font-family;
		font-weight: bold;
		max-width: 70%;
		padding: 7px 15px 7px 24px;
		background-color: rgba(255, 255, 255, .75);
		color: #353637;
		bottom: 8px;
		left: 0;

		&:before {
			content: "";
			display: block;
			position: absolute;
			background-color: #FF991B;
			width: 4px;
			top: 6px;
			bottom: 6px;
			left: 12px;
		}
	}

	&.font-small .title {
		font-size: 20px;
		max-width: 80%;
	}

	&.font-tiny .title {
		font-size: 16px;
		max-width: 90%;
	}


	.menu-item.dark .title {
		background-color: rgba(0, 0, 0, .75);
		color: white;
	}

	&.hiddentitle .title {
		display: none;
	}

	.lead {
		text-align: left;
		font-size: 16px;
		text-transform: none;
		margin: 4px 0;
	}

	&.hiddenlead .lead {
		display: none;
	}
}
