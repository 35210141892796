/**
 * Lábléc
 */
#site-footer {
	margin: 0;
	background-color: $foot-bg;
	text-align: left;
	font-family: $control-font-family;
	font-style: normal;
	font-weight: 500;
	color: #fff;

	.footer-panel {
		position: relative;
		height: 128px;
	}

	.logo {
		display: block;
		padding: 13px 40px 0px 20px;
		box-sizing: content-box;

		& > img {
			display: block;
		}
	}

	.copytext {
		display: block;
		position: absolute;
		left: 20px;
		bottom: 24px;
		font-size: 14px;
	}

	.menu-panel {
		display: block;
		position: absolute;
		top: 2px;
		right: 20px;
	}

	.emailform {
		position: absolute;
		bottom: 22px;
		right: 26px;

		.form-control {
			height: 44px;
			color: white;
			background-color: $site-secondary-color;
		}

		.btn {
			background-color: transparent;
			border-color: white;

			&:hover {
				background-color: transparent;
				color: white;
			}
		}
	}
}

#site-footer-info {
	width: 100%;
	font-family: $control-font-family;
	font-size: 17px;
	color: #005ea7;

	.footer-info-panel {
		padding: 4px 0 0 0;
		width: 100%;
		display: flex;
		background-color: white;
	}

	.left {
		margin-left: 12px;
		color: rgb(140, 150, 156);
	}

	.right {
		flex: 1;
		color: $site-secondary-color;
		text-align: right;
		margin-right: 12px;
	}

}
