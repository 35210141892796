/**
 * Menu
 */
.menu-panel {
	font-family: "Spartan", sans-serif;
	font-size: 17px;
	font-style: normal;
	font-variant: normal;
	font-weight: 500;
	line-height: 1.2;
	orphans: 1;
	text-decoration: none;
	text-indent: 0;

	@media (min-width: 768px) {
	}

	#site-footer & {
		display: block;
	}

	& > ul.menu {
		margin: 0;
		padding: 0;
		padding-left: 2px;
		background: transparent;
		display: flex;

		& > li {
			display: block;
			position: relative;
			margin: 0;
			padding: 0 17px;
			padding-top: $head-top-line-height;
			height: $head-top-line-height + $head-bottom-line-height;
			line-height: $head-bottom-line-height;
			white-space: nowrap;

			.icon {
				display: none;
			}

			&:hover {
				background: linear-gradient(to bottom,  #dcdcdc 0%,#ffffff 100%);

				#site-footer & {
					background: black;
				}
			}

			ul.menu {
				position: absolute;
				background-color: #aab3b8;
				padding: 8px 12px;
				margin-left: -10px;
				margin-top: -10px;
				display: none;

				@media (min-width: 768px) {
					color: $site-gray;
					font-family: $control-font-family;
					font-size: 16px;
					font-style: normal;
					font-variant: normal;
//					font-weight: 900;
					line-height: 2.8;
					orphans: 1;
					page-break-after: auto;
					page-break-before: auto;
					text-align: left;
					text-decoration: none;
					text-indent: 0;
					text-transform: uppercase;
					background-color: white;
					border-radius: 18px;
				}

				& > li {
					display: block;
					margin: 0;
					padding: 0;
					transition: background-color 0.2s;
					white-space: nowrap;

					&:hover {
						background-color: $site-secondary-color;
						color: white;
					}

					& > a.menu-item {
						display: block;
						padding: 0 38px 0 4px;
						margin: 0;
						border-bottom: 1px solid $site-secondary-color;
					}

					ul.menu {
						left: 100%;
						top: 0;
					}
				}
			}
		}
	}

	a {
		color: inherit;
	}

	a:hover {
		text-decoration: none;
		color: inherit;
	}

	.hamburger {
		display: none;
		position: fixed;
		right: 15px;
		top: 5px;
		cursor: pointer;
	}

	.more {
		display: none;
		float: right;
		position: relative;
		top: -42px;
		height: 42px;
		width: 42px;
		padding: 10px;
		margin: 0;
		color: white !important;

		&:hover {
			color: white !important;
		}
	}

	li.open > .more {
		padding-left: 8px;
	}

	@media (min-width: 768px) {
		& > ul.menu > li.selected {
			&::after {
				content: "";
				position: absolute;
				left: 17px;
				right: 17px;
				bottom: 0px;
				height: 4px;
				background-color: $site-secondary-color;
			}
		}

		ul.menu > li:hover > ul.menu {
			display: block;
		}
	}
}

.menu-panel > ul.menu > li ul.menu > li ul.menu > li > a.menu-item {
	padding-left: 60px;
}

@media (min-width: 768px) {
	.menu-panel > ul.menu > li ul.menu > li:last-child > a {
		border-bottom: none;
	}
}

#site-header.open .menu-panel {
	display: block;
}

@media (max-width: 767.97px) {
	.menu-panel > ul.menu {
		display: none;
		font-size: 17px;
		padding: 15px;
		line-height: 30px;
		color: $site-gray;

		ul.menu {
			color: white;
		}

		#site-header.open & {
			display: block;
		}

		& > li {
			display: block;
			float: none;
			line-height: 35px;
			height: auto;
			padding: 0;

			.more {
				color: $site-gray !important;
			}

			&:hover {
				background-color: $site-secondary-color;
				color: white;

				.more {
					color: white !important;
				}
			}

			& > a {
				display: block;
				padding-left: 5px;
			}
		}

		a.menu-item {
			border-bottom: 1px solid $site-secondary-color;

			&:hover {
				background-color: $site-secondary-color;
				color: white;
				background-clip: border-box;
			}
		}
	}

	.menu-panel ul.menu > li.open ul.menu {
		display: block;
		position: static;
		background-color: transparent;
		margin: 0;
		padding: 0;
	}

	.menu-panel > ul.menu > li ul.menu > li > a.menu-item {
		padding: 0 0 0 30px;
		font-size: 20px;
	}

	.menu-panel ul.menu > li.opened ul.menu,
	.menu-panel ul.menu > li.opened:hover > ul.menu {
		display: block;
		position: static;
		background-color: transparent;
		margin: 0;
	}

	#site-header.open .menu-panel .more {
		display: block;
		font-size: 36px;
		top: -45px;
		width: 50px;
		height: 35px;
		line-height: 35px;
	}

	#site-header.open {
		position: fixed;
		width: 100vw;
		height: 100vh;
	}
}

@keyframes menu-open {
	from {
		opacity: 0;
		left: 100%;
	}

	to {
		opacity: 1;
		left: 50px;
	}
}

@keyframes menu-shader-open {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
