body.fixed-header {
	@media (min-width: 768px) {
		margin-top: $head-top-line-height + $head-separator-height + $head-bottom-line-height;
	}
}

/**
 * Fejléc
 */
#site-header {
	position: relative;
	z-index: 10;
	font-family: $control-font-family;
	font-style: normal;
	font-weight: 500;
	max-width: $site-width;
	margin: 0 auto;
	color: $site-gray;

	.mobile,
	.mobile-inline,
	.mobile-inline-block,
	.desktop {
		display: none;
	}

	a {
		color: inherit;
	}

	@media (min-width: 768px) {
		height: $head-top-line-height + $head-separator-height + $head-bottom-line-height;
		background-color: white;

		body.view-wide-header & {
			max-width: initial;
		}

		&.fixed {
			position: fixed;
			top: 0;
			width: 100%;
			height: $head-bottom-line-height;
			box-shadow: 0px -1px 6px #0000004f;

			& .top-line {
				display: none;
			}
		}

		.desktop {
			display: block;
		}
	}

	& > .hamburger {
		display: block;
		position: fixed;
		right: 10px;
		top: 10px;
		cursor: pointer;
		z-index: 2;
		font-size: 50px;
		width: 54px;
		height: 50px;
		line-height: 50px;
		color: white;
		background-color: $site-secondary-color;
		border-radius: 1px;

		&:hover {
			color: black !important;
		}

		@media (min-width: 768px) {
			display: none;
		}
	}

	.top-line,
	.bottom-line {
		display: none;
	}

	@media (min-width: 768px) {
		.top-line,
		.bottom-line {
			display: flex;
			align-items: center;
		}

		.top-line {
			height: $head-top-line-height;
			position: relative;
			z-index: 1;
		}

		.bottom-line {
//			border-top: $head-separator-height solid #3494ba;
			height: $head-bottom-line-height + $head-separator-height;
		}
	}

	.logo-panel {
		.logo {
			display: block;
		}
	}

	@media (min-width: 768px) {
		.staticmenu {
			&.right {
				margin-left: auto;
			}

			&.rpadding {
				padding-right: 20px;
			}

			&.rpadding-w {
				padding-right: 50px;
			}

			&.info {
				font-size: 20px;
				font-weight: normal;

				.item {
					padding-right: 20px;
				}
			}

			&.mini {
				font-family: Roboto, sans-serif;
				font-size: 14px;
				font-weight: normal;
				line-height: 2.2;

				.item {
					&:after {
						content: " |";
						padding-left: 3px;
						padding-right: 3px;
					}

					&.last:after {
						display: none;
					}
				}
			}

			&.social {
				display: flex;

				.item {
					margin-right: 10px;
					font-size: 3rem;

					&:hover {
						color: $site-secondary-color;
					}
				}
			}
		}
	}

	.icon {
		&:hover {
			text-decoration: none;
			color: #9c1b42;
		}
	}

	.menu-block {
		@media (min-width: 768px) {
			padding-right: 43px;
			padding-left: 20px;
			align-self: end;
			height: $head-top-line-height + $head-bottom-line-height;
			align-self: flex-end;
		}
	}

	&.open {
		width: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: white;
		animation: 0.4s cubic-bezier(1,0,0,1) header-open;

		.top-line,
		.bottom-line {
			display: block;
		}

		.mobile {
			display: block;
		}

		.mobile-inline {
			display: inline;
		}

		.mobile-inline-block {
			display: inline-block;
		}

		.mobile-logo-panel {
			background-color: $head-bg;
			padding: 10px 15px;
			min-height: 66px;

			img.mobile {
				transform-origin: 0 0;
				animation: 0.5s cubic-bezier(1,0,0,1) header-open-logo;
			}

			.logo {
				@media (max-width: 767.95px) {
					display: inline-flex;
					min-height: 46px;
					align-items: center;
				}
			}
		}

		.mobile-top-line {
			display: flex;
			align-items: center;
			margin: 15px;
			padding-bottom: 15px;
			border-bottom: 1px solid $site-secondary-color;

			.social {
				margin-left: auto;
			}

			.item {
				font-size: 30px;
				line-height: 30px;
				margin: 0 0.5rem;
			}

		}

		.staticmenu {
			position: absolute;
			width: 100%;
			margin: 15px;

			&.info {
				bottom: 144px;

				& > .item {
					font-size: 16px;
					line-height: 2;
				}
			}

			&.mini {
				bottom: 0;
				color: #cc9035;
				font-size: 22px;
			}

			.item {
				display: block;
			}
		}

		.menu-block {
			width: 100%;
			max-height: calc(100vh - 423px);
			overflow: scroll;
		}
	}

}

@keyframes header-open {
	from {
		opacity: 0;
		transform: translateX(-100%);
	}

	to {
		opacity: 1;
		transform: translateX(0%);
	}
}

@keyframes header-open-logo {
	from {
		opacity: 0;
		transform: scale(10);
	}

	40% {
		opacity: 0;
		transform: scale(10);
	}

	to {
		opacity: 1;
		transform: scale(1);
	}
}