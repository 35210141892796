/**
 * Boxpanel
 */
.boxpanel-fluid {
	width: 100%;
	padding: 0;
	// overflow: hidden;

	.boxpanel-row-base {
		padding: 0 15px;
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;

		background-color: transparent;
		background-position: inherit;
		background-repeat: inherit;
		background-size: inherit;
		background-attachment: inherit;

		.sidebar & {
			padding: 0;
		}
	}

	& > .boxpanel-container {
		width: 100%;
		padding: 0;
		position: relative;

		background-color: white;

		&.slim {
			background-color: transparent;

			& > .boxpanel-row-base {
				background-color: white;
			}
		}

		&.bcg {
			background-color: transparent;

			& > .boxpanel-row-base {
				background-color: transparent;
			}

			& > .boxpanel-video-bcg {
				display: block;
			}
		}

		&.wide {
			&.bcg {
				&.bcg-color {
					background-color: transparent;
					background-color: var(--box-background-color);
				}

				&.bcg-image {
					background-image: var(--box-background-image);
				}
			}
		}

		&.slim {
			&.bcg {
				&.bcg-color > .boxpanel-row-base {
					background-color: var(--box-background-color);
					padding: 0 $box-vgap / 2;
				}

				&.bcg-image > .boxpanel-row-base {
					background-image: var(--box-background-image);
					padding: 0 $box-vgap / 2;
				}
			}

			& > .boxpanel-video-bcg {
				max-width: 1280px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		&.full {
			& > .boxpanel-row-base {
				max-width: initial;
				margin-left: 0;
				margin-right: 0;
			}
		}

		& > .boxpanel-video-bcg {
			display: none;
			position: absolute;
			top: 0;
			bottom: 0;
			width: 100%;
			overflow: hidden;
		}
	}

	.boxpanel-row {
		.sidebar & {
			margin-left: 0;
			margin-right: 0;
		}

		@each $breakpoint in map-keys($grid-breakpoints) {
			@include media-breakpoint-up($breakpoint) {
//				$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
				&.fixed-height-#{$breakpoint} {
					min-height: var(--box-min-height-#{$breakpoint});

					&:before {
						content: none;
					}
				}

				&.fixed-height-#{$breakpoint}-percent {
					min-height: 0;

					&:before {
						display: block;
						width: 0;
						content: "";
						margin: 0;
						padding-left: 0;
						padding-right: 0;
						padding-top: 0;
						padding-bottom: var(--box-min-height-#{$breakpoint});
					}
				}
			}
		}
	}

	video.boxpanel-video {
		position: absolute;
		width: 100%;
	}

}

.bcg {
	background-size: auto;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: scroll;

	&.bcg-fix { background-attachment: fixed; }
	&.bcg-r-x { background-repeat: repeat-x; }
	&.bcg-r-y { background-repeat: repeat-y; }
	&.bcg-r-all { background-repeat: repeat; }
	&.bcg-s-cover { background-size: cover; }
	&.bcg-s-contain { background-size: contain; }
	&.bcg-p-left { background-position: left; }
	&.bcg-p-right { background-position: right; }
	&.bcg-p-top { background-position: top; }
	&.bcg-p-bottom { background-position: bottom; }
	&.bcg-p-top-left { background-position: top left; }
	&.bcg-p-bottom-left { background-position: bottom left; }
	&.bcg-p-top-right { background-position: top right; }
	&.bcg-p-bottom-right { background-position: bottom right; }

	&.bcg-s-contain {
		.boxpanel-video {
			height: 100%;
		}
	}

	&.bcg-s-cover {
		.boxpanel-video {
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			// z-index: -1000;
			overflow: hidden;
		}
	}

	&.bcg-p-left {
		.boxpanel-video {
			left: 0;
		}
	}
	&.bcg-p-right {
		.boxpanel-video {
			right: 0;
		}
	}
	&.bcg-p-top {
		.boxpanel-video {
			top: 0;
		}
	}
	&.bcg-p-bottom {
		.boxpanel-video {
			bottom: 0;
		}
	}
	&.bcg-p-top-left {
		.boxpanel-video {
			top: 0;
			left: 0;
		}
	}
	&.bcg-p-bottom-left {
		.boxpanel-video {
			bottom: 0;
			left: 0;
		}
	}
	&.bcg-p-top-right {
		.boxpanel-video {
			top: 0;
			right: 0;
		}
	}
	&.bcg-p-bottom-right {
		.boxpanel-video {
			bottom: 0;
			right: 0;
		}
	}

}
