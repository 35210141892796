$site-font-family: Spartan, sans-serif;
$control-font-family: Spartan, sans-serif;
$content-font-family: Spartan, sans-serif;
$site-font-size: 11px;
$site-color: #353637;
$site-secondary-color: #ff9a1b;
$site-gray: #79868d;
$site-background: #fff;
$site-width: 1280px;

$box-hgap: 30px;
$box-vgap: 30px;

/**
 * Header
 */
$head-bg: $site-secondary-color;
$head-top-line-height: 59px;
$head-separator-height: 0;
$head-bottom-line-height: 55px;
$head-logo-height: 30px;

/**
 * Footer
 */
$foot-bg: #353637;