/* contentlist */
div.boxpanel.w1 div.boxpanel-cell.w1 .box.contentlist.pictures,
div.boxpanel.w2 div.boxpanel-cell.w2 .box.contentlist.pictures,
div.boxpanel.w3 div.boxpanel-cell.w3 .box.contentlist.pictures,
div.boxpanel.w4 div.boxpanel-cell.w4 .box.contentlist.pictures {
	margin-left: -13px;
	margin-right: -13px;
}

.box.contentlist {
	margin-left: 0;
	margin-right: 0;
	font-family: $content-font-family;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1.2;

	& > h1 + hr {
		margin-bottom: 12px;
	}

	&.hiddenboxtitle > h1,
	&.hiddenboxtitle > h1 + hr {
		display: none;
	}

	&.hiddentitle {
		h2.box-header {
			display: none;
		}

		h3.box-header.subtitle {
			margin-top: 4px;
		}
	}

	&.hiddensubtitle h3.box-header.subtitle {
		display: none;
	}

	.subbox {
		background-color: transparent;
		transition: background-color 0.3s;
		color: black;

		&.canhover:hover {
			background-color: #d4d8da;
		}

		&.c-product {
			& > .subbox-item {
				position: relative;
				border: 1px solid #8C8D93;
				padding: 4px;
				margin-bottom: 30px;
			}

			a.more {
				display: block;
			}

			.box-header {
				display: none;
			}

			.box-product-header {
				display: block;
			}

			.box-content {
				margin-left: 10px;
				margin-right: 10px;
				border: none;
				margin-bottom: 1em;
			}
		}

		&.dark {
			.box-product-header {
				background-color: rgba(0, 0, 0, .75);

				& > .title {
					color: white;
				}
			}
		}
	}

	.box-header {
		margin: 8px 0 4px 0;
		color: $site-secondary-color;
		font-size: 24px;
		font-weight: bold;

		& > a.title {
			color: inherit;

			&:hover {
				text-decoration: none;
			}
		}

		&.subtitle {
			margin-top: -3px;
		}

		&.date {
			display: none;
			margin-top: 3px;
			margin-bottom: 0;
			font-weight: normal;
			color: #808080;
			font-size: 11px;
		}
	}

	.box-product-header {
		display: none;
		position: absolute;
		top: 14px;
		left: 0;
		background-color: rgba(255, 255, 255, .75);
		padding: 10px 15px 10px 30px;
		font-size: 20px;
		font-weight: bold;

		& > .title {
			display: block;
			position: relative;
			color: #353637;

			&:before {
				content: "";
				display: block;
				position: absolute;
				background-color: #FF991B;
				width: 4px;
				top: -1px;
				bottom: 1px;
				left: -14px;
			}
		}

		& > .price {
			display: block;
			font-weight: 900;
			color: #FF991B;
			margin-top: 4px;
		}
	}

	.box-cproperty-list {
		display: flex;
		padding: 0;
		margin-left: -10px !important;
		margin-right: -10px !important;
		font-size: 12px;
		margin-top: 1rem;
		margin-bottom: 1rem;

		& > li {
			display: block;
		}

		.icon {
			font-size: 14px;
			width: 12px;
			height: 12px;
			line-height: 12px;

			color: #FF991B;
		}
	}

	&.items-6 .box-header {
		font-size: 80%;
	}

	.box-content {
		margin: 10px 0 5px 0;
		padding-bottom: 10px;
		border-bottom: 1px solid #bbbbbb;
		color: #666666;
		font-size: 14px;
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		line-height: 1.2;
	}

	a.more {
		display: none;
		position: absolute;
		right: 5px;
		bottom: -21px;
		height: 42px;
		line-height: 42px;
		border-radius: 21px;
		color: black;
		background-color: #FF991B;
		font-size: 16px;
		font-weight: bold;
		padding-left: 22px;
		padding-right: 22px;
	}

	.image {
		width: 100%;
		position: relative;

		& > a.shader {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 1;
		}

		& > img {
			width: 100%;
			max-width: 100%;
		}

		.alert-title {
			display: block;
			position: absolute;
			right: 0px;
			top: 16px;
			font-size: 14px;
			background-color: $site-secondary-color;
			padding: 6px 12px 6px 16px;
			color: white;
		}
	}

	.highlighted .image {
		border-bottom-color: $site-secondary-color;
	}

	&.white-txt,
	&.red-bcg {
		.subbox {
			color: white;

			&.canhover:hover {
				background-color: transparent;
			}

			.txt .red {
				color: white;
			}
		}

		.box-header {
			color: white;

			&.subtitle {
				color: white;
			}
		}

		.box-content {
			color: white;
			border-bottom-color: white;
		}
	}

	&.white-bcg {
		.row {
			margin-left: 0;
			margin-right: 0;
		}

		.subbox {
			padding: $box-vgap / 2;
		}

		.box-content {
			padding-bottom: 0;
			border-bottom: 0;
		}
	}


	&.wide {
		.box-imagehalf {
			display: none;
		}

		@media (min-width: 576px) {
			& > .widebox {
				display: flex;
			}

			&.imgleft > .widebox {
				flex-direction: row;

				&.even {
					flex-direction: row-reverse;
				}
			}

			&.imgright > .widebox {
				flex-direction: row-reverse;

				&.even {
					flex-direction: row;
				}
			}

			.box-texthalf,
			.box-imagehalf {
				display: block;
				margin-left: $box-vgap / 2;
				margin-right: $box-vgap / 2;
				width: 50%;
			}

			.box-texthalf {
				margin-top: 16px;
				margin-bottom: 16px;
			}
			.box-imagehalf {
			}

			&.imgleft .odd,
			&.imgright .even {
				.box-texthalf {
					margin-right: 0;
					padding-right: $box-vgap;
				}
				.box-imagehalf {
					margin-left: 0;
				}
			}

			&.imgright .odd,
			&.imgleft .even {
				.box-texthalf {
					margin-left: 0;
					padding-left: $box-vgap;
				}
				.box-imagehalf {
					margin-right: 0;
				}
			}

			.image {
				height: 100%;
				background-size: cover;
				background-position: center;

				&.mobile {
					display: none;
				}
			}
		}
	}

	&.pictures {

		.subbox {
			padding: 0;
			position: relative;
			left: 0;
			top: 0;
			overflow: hidden;
		}

		.row {
			margin-left: 0;
			margin-right: 0;
		}

		.box-content,
		a.more {
			display: none;
		}

		.box-header {
			background-color: $site-secondary-color;
			color: white;
			position: absolute;
			display: flex;
			align-items: center;
			z-index: 2;
			left: 0;
			right: 0;
			top: 0;
			min-height: 50px;
			max-height: 80px;
			padding: 4px 12px;
			margin: 0;
			transition: top 0.3s;
			overflow: hidden;

			&.subtitle,
			&.date {
				display: none;
			}
		}

		.image {
			border-bottom: none;

			.alert-title {
				top: unset;
				right: unset;
				left: 0;
				bottom: 20px;
			}
		}
	}

	&.product {

		.subbox {
			color: #353637;

			& > .subbox-item {
				border: 12px solid #e8e8e8;
				border-top-width: 20px;
				border-bottom-width: 20px;
				padding: 15px;
				background-clip: padding-box;
			}

			&.highlighted {
				& > .subbox-item {
					border-color: black;
				}
			}

			&.canhover {
				&:hover {
					background-color: transparent;
				}

				& > .subbox-item {
					transition: background-color 0.3s, border-color 0.3s;

					&:hover {
						border-color: transparent;
						background-color: #e8e8e8;
					}
				}
			}
		}

		.box-header {
			color: inherit;
			margin: 2px 0 0 0;
		}

		.box-content {
			font-size: 15px;
			border-bottom: none;
			color: inherit;
			margin: 0;
		}

		.product-control {
			display: flex;
			width: 100%;
			align-items: center;
			margin-top: 5px;

			.price {
				font-size: 15px;
			}

			.rent {
				margin-left: auto;
				padding-right: 5px;

				&:last-child {
					padding-right: 0;
				}
			}
		}

		.btn.btn-primary:hover {
			color: black;
		}
	}
}

@media (min-width: 768px) {
	.box.contentlist.pictures.float > .subbox {
		box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
		transition: left 0.2s, top 0.2s, box-shadow 0.3s;
	}
	.box.contentlist.pictures.zoom .image {
		transition: transform 0.9s;
	}
	.box.contentlist.pictures.float .subbox:hover {
		background-color: transparent;
		left: -10px;
		top: -10px;
		box-shadow: 6px 6px 10px 0px rgba(0,0,0,0.45);
	}
	.box.contentlist.pictures.zoom .subbox:hover .image:hover {
		transform: scale(1.13);
		z-index: 1;
	}

	.box.contentlist.pictures .box-header {
		top: -80px;
	}
	.box.contentlist.pictures .subbox:hover .box-header {
		top: 0;
	}
	.box.contentlist.pictures.zoom .image > a.shader {
		background-color: rgba(0, 0, 0, 0);
		transition: background-color 0.4s;
	}
	.box.contentlist.pictures.zoom .subbox:hover .image > a.shader {
		background-color: rgba(0, 0, 0, 0.5);
	}
}
