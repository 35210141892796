.box.box-map {
	& > .map {
		width: 100%;
		min-height: 66vh;
	}
}

.map-popup {

	h1.firstHeading {
		text-align: center;
	}

	h3.subHeading {
		font-size: 16px;
	}

	img.bodyImage {
		display: block;
		margin: 2px auto;
		max-width: 100%;
		max-height: 600px;
	}

	ul.bodyCProperty,
	ul.bodyGroup {
		display: block;
		margin: 0;
		padding: 0;

		li {
			display: flex;
			margin: 2px;
			padding: 2px 1em;
			border-bottom: 1px dotted #aaaaaa;
			align-items: center;
		}

		.cell {
			display: block;
		}
	}

	ul.bodyCProperty {
		.key {
			width: 33%;
		}
	}

	ul.bodyGroup {
		.gicon {
			padding-right: 6px;

			& > img {
				max-width: 24px;
			}
		}
	}

	.bodyContent {
		margin-top: 5px;
		margin-bottom: 5px;
	}
}

.box.box-map {
	.map-filter {
		user-select: none;
		display: flex;
		align-items: center;

		&.checked {
			background-color: #9cb38b;
		}

		& > span {
			display: block;
			padding-left: .2em;
		}
	}
}
