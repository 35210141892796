/**
 * képrotátor (image slider)
 */
.box.box-imagelist.slider {
	& > .frame {
		position: relative;
		overflow: hidden;
		padding: 0;
	}

	&.slider-head {
		margin-left: -15px;
		margin-right: -15px;
	}

	.slides,
	.control {
		display: block;
		margin: 0;
		padding: 0;
		z-index: 1;
	}

	.slides {
		& > .item {
			display: block;
			position: absolute;
			left: 0px;
			top: 0px;
			right: 0px;
			bottom: 0px;
			z-index: 0;

			&.show {
				z-index: 1;
			}

			&.hidden {
				display: none;
			}

			& > .text-panel {
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				padding: 32px;

				animation-duration: 0.7s;
			}

			&.show.right div.image {
				animation-name: slider-image-in-right;
			}
			&.hide.right div.image {
				animation-name: slider-image-out-right;
			}
			&.show.left div.image {
				animation-name: slider-image-in-left;
			}
			&.hide.left div.image {
				animation-name: slider-image-out-left;
			}
			&.show.opacity div.image {
				animation-name: slider-image-in-opacity;
			}
			&.hide.opacity div.image {
				animation-name: slider-image-out-opacity;
			}
			&.show.tv div.image {
				animation-name: slider-image-in-tv;
			}
			&.hide.tv div.image {
				animation-name: slider-image-out-tv;
			}
			&.show.zoom div.image {
				animation-name: slider-image-in-zoom;
			}
			&.hide.zoom div.image {
				animation-name: slider-image-out-zoom;
			}
			&.show.rota div.image {
				animation-name: slider-image-in-rota;
			}
			&.hide.rota div.image {
				animation-name: slider-image-out-rota;
			}

			&.show div.text {
				animation-name: slider-text-in;
			}
			&.hide div.text {
				animation-name: slider-text-out;
			}
		}

		div.image {
			display: block;
			position: relative;
			width: 100%;

			animation-duration: 1.1s;

			& > img {
				width: 100%;
				display: block;
				border: 0;
			}
		}
	}

	div.control {
		opacity: 0.5;
		position: absolute;
		left: 50%;
		bottom: 2vw;

		transition: opacity 300ms;

		& > ul {
			display: block;
			padding: 0;
			margin: 0;
			margin-left: -50%;

			& > li {
				display: inline-block;
				border: none;
				margin: 0 5px;
				cursor: pointer;
				border-radius: 10px;
				border: 2px solid transparent;

				transition: border-color 0.7s;

				&.showing {
						border: 2px solid #fff;
				}
			}
		}
	}


	&:hover div.control {
		opacity: 1;
	}

	.dot {
		display: block;
		border-radius: 8px;
		background-color: black;
		width: 16px;
		height: 16px;
		margin: 1px;
	}
}

@keyframes slider-image-in-right {
	from {
		left: 100%;
	}

	to {
		left: 0;
	}
}

@keyframes slider-image-out-right {
	from {
		left: 0;
	}

	to {
		left: -100%;
	}
}

@keyframes slider-image-out-left {
	from {
		left: 0;
	}

	to {
		left: 100%;
	}
}

@keyframes slider-image-in-left {
	from {
		left: -100%;
	}

	to {
		left: 0;
	}
}

@keyframes slider-image-out-opacity {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes slider-image-in-opacity {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes slider-image-out-tv {
	from {
		transform: scaleY(1) scaleX(1);
		opacity: 1;
	}

	to {
		transform: scaleY(0.01) scaleX(1.5);
		opacity: 0;
	}
}

@keyframes slider-image-in-tv {
	from {
		transform: scaleY(0.7) scaleX(1.2);
		opacity: 0;
	}

	to {
		transform: scaleY(1) scaleX(1);
		opacity: 1;
	}
}

@keyframes slider-image-out-zoom {
	from {
		transform: scale(1);
		opacity: 1;
	}

	to {
		transform: scale(3);
		opacity: 0;
	}
}

@keyframes slider-image-in-zoom {
	from {
		transform: scale(0.3);
		opacity: 0;
	}

	to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes slider-image-out-rota {
	from {
		transform: scale(1) rotate(0);
		opacity: 1;
	}

	to {
		transform: scale(0.1) rotate(980deg);
		opacity: 0;
	}
}

@keyframes slider-image-in-rota {
	from {
		transform: scale(0.3) rotate(-30deg);
		opacity: 0.5;
	}

	to {
		transform: scale(1) rotate(0);
		opacity: 1;
	}
}

@keyframes slider-text-in {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes slider-text-out {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}
