/* title */
.box.box-title,
.title-panel {
	text-align: center;
	font-family: $control-font-family;
	font-weight: 500;
	color: #353637;

	&.white-txt {
		color: white;

		& > hr.sep {
			border-color: white;
		}
	}
	&.h-margin-1 {
		margin-left: 15px;
		margin-right: 15px;
	}
	&.h-margin-2 {
		margin-left: 30px;
		margin-right: 30px;
	}

	&.left {
		text-align: left;
	}
	&.right {
		text-align: right;
	}

	&.shade-bcg {
		background-color: rgba(255, 255, 255, .7);
		padding: 1px 15px 10px 15px;

		&.white-txt {
			background-color: rgba(0, 0, 0, .5);
		}
	}

	& > h1 {
		width: 100%;
		text-align: inherit;

		font-family: Relaway, sans-serif;
//		font-size: 68px;
		font-style: normal;
		font-variant: normal;
		font-weight: bold;
		line-height: 1;
		color: inherit;

		margin-top: 1rem;
		margin-bottom: 1rem;

		font-size: 28px;

/*
--breakpoint-xs: 0;
--breakpoint-sm: 576px;
--breakpoint-md: 768px;
--breakpoint-lg: 992px;
--breakpoint-xl: 1200px;
*/

		@media (min-width: 576px) {
			font-size: 38px;
		}

		@media (min-width: 768px) {
			font-size: 48px;
		}

		@media (min-width: 992px) {
			font-size: 58px;
		}

		@media (min-width: 1200px) {
			font-size: 68px;
		}
	}

	&.small-font > h1 {
		font-size: (28px / 1.5111);

		@media (min-width: 576px) {
			font-size: (38px / 1.5111);
		}

		@media (min-width: 768px) {
			font-size: (48px / 1.5111);
		}

		@media (min-width: 992px) {
			font-size: (58px / 1.5111);
		}

		@media (min-width: 1200px) {
			font-size: (68px / 1.5111);
		}
	}

	& > h2 {
		width: 100%;
		text-align: inherit;

		font-family: "Roboto Lt", sans-serif;
		font-size: 20px;
		font-style: normal;
		font-variant: normal;
		font-weight: 900;
		line-height: 1.2;

		margin-top: 2.4rem;
		margin-bottom: 1rem;
	}

	& > hr.sep {
		border-top: 5px solid $site-secondary-color;
		max-width: 25px;
		margin-top: 1.5rem;
		margin-left: auto;
		margin-right: auto;
	}

	&.right > hr.sep {
		margin-right: 0;
	}

	&.left > hr.sep {
		margin-left: 0;
	}

	& > .controls {
		margin-bottom: 1rem;

		.btn.btn-primary {
			background-color: $site-secondary-color;
		}
	}
}
